/*!
 * /    Copyright 2019 Brigham Young University
 * /
 * /    Licensed under the Apache License, Version 2.0 (the "License");
 * /    you may not use this file except in compliance with the License.
 * /    You may obtain a copy of the License at
 * /
 * /        http://www.apache.org/licenses/LICENSE-2.0
 * /
 * /    Unless required by applicable law or agreed to in writing, software
 * /    distributed under the License is distributed on an "AS IS" BASIS,
 * /    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * /    See the License for the specific language governing permissions and
 * /    limitations under the License.
 * / */
/*!
 /    Copyright 2019 Brigham Young University
 /
 /    Licensed under the Apache License, Version 2.0 (the "License");
 /    you may not use this file except in compliance with the License.
 /    You may obtain a copy of the License at
 /
 /        http://www.apache.org/licenses/LICENSE-2.0
 /
 /    Unless required by applicable law or agreed to in writing, software
 /    distributed under the License is distributed on an "AS IS" BASIS,
 /    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 /    See the License for the specific language governing permissions and
 /    limitations under the License.
 / */
@import "https://cdn.byu.edu/theme-fonts/1.x.x/ringside/fonts.css";
@import "https://cdn.byu.edu/theme-fonts/1.x.x/public-sans/fonts.css";
/*
 * Corner Radius
 */
/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * Spacing
 */
/*
 * Breakpoints
 */
/*
 * Foundations
 */
@import "https://cdn.byu.edu/theme-fonts/1.x.x/ringside/fonts.css";
@import "https://cdn.byu.edu/theme-fonts/1.x.x/public-sans/fonts.css";
/*!
 /    Copyright 2019 Brigham Young University
 /
 /    Licensed under the Apache License, Version 2.0 (the "License");
 /    you may not use this file except in compliance with the License.
 /    You may obtain a copy of the License at
 /
 /        http://www.apache.org/licenses/LICENSE-2.0
 /
 /    Unless required by applicable law or agreed to in writing, software
 /    distributed under the License is distributed on an "AS IS" BASIS,
 /    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 /    See the License for the specific language governing permissions and
 /    limitations under the License.
 / */
/*!
 /    Copyright 2019 Brigham Young University
 /
 /    Licensed under the Apache License, Version 2.0 (the "License");
 /    you may not use this file except in compliance with the License.
 /    You may obtain a copy of the License at
 /
 /        http://www.apache.org/licenses/LICENSE-2.0
 /
 /    Unless required by applicable law or agreed to in writing, software
 /    distributed under the License is distributed on an "AS IS" BASIS,
 /    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 /    See the License for the specific language governing permissions and
 /    limitations under the License.
 / */
@import "https://cdn.byu.edu/theme-fonts/1.x.x/ringside/fonts.css";
@import "https://cdn.byu.edu/theme-fonts/1.x.x/public-sans/fonts.css";
/*
 * Corner Radius
 */
/*
 * Colors
 */
/*
 * Fonts
 */
/*
 * Spacing
 */
/*
 * Breakpoints
 */
/*
 * Foundations
 */
byu-header:not(.byu-component-rendered) {
  background-color: #002e5d;
  z-index: 10;
  font-size: 1.125rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  border-bottom: 1px solid #ddd;
  font-family: "HCo Ringside Narrow SSm", "Open Sans", Helvetica, Arial, sans-serif;
  display: grid;
  grid-template-columns: 4.75em 1px auto repeat(3, min-content);
  grid-template-rows: repeat(4, min-content);
  padding: 0; }
  byu-header:not(.byu-component-rendered) a, byu-header:not(.byu-component-rendered) a:link, byu-header:not(.byu-component-rendered) a:visited {
    color: #fff;
    text-decoration: none;
    transition: opacity 200ms ease; }
  byu-header:not(.byu-component-rendered)::before {
    background-image: url(https://cdn.byu.edu/shared-icons/latest/logos/monogram-white.svg);
    content: "";
    height: 20.5px;
    width: 72px;
    margin: auto 24px;
    background-size: cover;
    grid-area: 1/1/4/2; }
  byu-header:not(.byu-component-rendered)::after {
    grid-area: 1/2/4/3;
    background-color: rgba(255, 255, 255, 0.25);
    content: "";
    margin: 12px 0;
    min-height: 40px; }
  byu-header:not(.byu-component-rendered) byu-breadcrumbs {
    display: block;
    clear: right;
    grid-area: 1/3/2/4;
    margin: 8px 24px 0;
    font-size: 20px; }
    byu-header:not(.byu-component-rendered) byu-breadcrumbs a {
      opacity: 0.75;
      margin-right: 0.5em;
      padding-right: 0.75em;
      border-right: solid 1px rgba(255, 255, 255, 0.25);
      font-size: 16px; }
    byu-header:not(.byu-component-rendered) byu-breadcrumbs a:hover, byu-header:not(.byu-component-rendered) byu-breadcrumbs a:focus {
      opacity: 1; }
    byu-header:not(.byu-component-rendered) byu-breadcrumbs a:last-child {
      border: none; }
  byu-header:not(.byu-component-rendered) *[slot="site-title"] {
    color: #fff;
    grid-area: 2/3/3/4;
    font-size: 24px;
    margin: 6px 24px; }
  byu-header:not(.byu-component-rendered) .subtitle[slot="site-title"] {
    grid-area: 3/3/4/4;
    color: #fff;
    font-size: 16px;
    margin: 6px 24px;
    position: relative;
    top: -6px; }
  byu-header:not(.byu-component-rendered) byu-user-info {
    grid-area: 1/5/4/6;
    display: flex;
    font-size: 16px;
    margin: auto 24px; }
  byu-header:not(.byu-component-rendered) byu-user-info > * {
    margin: 0 16px; }
  byu-header:not(.byu-component-rendered) *[slot="user-name"] {
    display: none; }
  byu-header:not(.byu-component-rendered) *[slot="login"] {
    min-width: 89px;
    display: flex;
    align-items: center; }
    byu-header:not(.byu-component-rendered) *[slot="login"]::before {
      background-image: url(https://cdn.byu.edu/shared-icons/latest/fontawesome/user-circle-o-white.svg);
      content: "";
      height: 20px;
      width: 20px;
      background-size: cover;
      margin: 0 9px 0 0; }
  byu-header:not(.byu-component-rendered) *[slot="logout"] {
    min-width: 64px;
    display: none; }
  byu-header:not(.byu-component-rendered) *[slot="actions"] {
    grid-area: 1/4/4/5;
    background-color: #0055af;
    font-size: 16px;
    padding: 8px 24px;
    max-width: 103px;
    text-align: center;
    vertical-align: middle;
    height: 17px;
    margin: auto 16px; }
  byu-header:not(.byu-component-rendered) byu-search {
    background-color: #002e5d;
    border-radius: 15px;
    height: 35px;
    width: 144px;
    grid-area: 1/6/4/7;
    margin: auto 16px auto 0; }
    byu-header:not(.byu-component-rendered) byu-search::before {
      content: "Must have JS";
      color: #000;
      font-size: 16px;
      font-family: "HCo Ringside Narrow SSm", "Open Sans", Helvetica, Arial, sans-serif;
      font-weight: 400;
      padding: 6px 16px; }
    byu-header:not(.byu-component-rendered) byu-search::after {
      background-image: url(https://cdn.byu.edu/shared-icons/latest/fontawesome/search-navy.svg);
      content: "";
      height: 17px;
      width: 17px;
      background-size: cover;
      position: absolute;
      top: 45px;
      right: 45px; }
    byu-header:not(.byu-component-rendered) byu-search input[type="search"] {
      display: none; }
  byu-header:not(.byu-component-rendered) byu-menu {
    background-color: #fff;
    color: #002e5d;
    grid-area: 4/1/5/7;
    display: flex;
    padding: 0 0 0 117px; }
    byu-header:not(.byu-component-rendered) byu-menu a {
      color: #002e5d !important;
      font-size: 16px;
      padding: 11px 22px;
      margin: 0 6px 0;
      line-height: 1.6;
      border-bottom: solid 3px rgba(0, 0, 0, 0); }
      byu-header:not(.byu-component-rendered) byu-menu a.is-active {
        border-bottom: solid 3px #002e5d !important; }
      byu-header:not(.byu-component-rendered) byu-menu a:hover {
        background-color: #fafafa !important;
        border-bottom: solid 3px #002e5d !important; }
  @media (min-width: 50rem) {
    byu-header:not(.byu-component-rendered) {
      font-size: 1.3125rem; } }
  @media (min-width: 60rem) {
    byu-header:not(.byu-component-rendered) {
      font-size: 1.5rem; } }
  @media (max-width: 60rem) {
    byu-header:not(.byu-component-rendered) {
      grid-template-columns: 100px 1px auto repeat(3, min-content); }
      byu-header:not(.byu-component-rendered)::before {
        height: 18px;
        width: 62px;
        margin: auto 22px; }
      byu-header:not(.byu-component-rendered) byu-breadcrumbs {
        margin: 10px 50px 0 20px;
        font-size: 16px; }
        byu-header:not(.byu-component-rendered) byu-breadcrumbs a {
          font-size: 14px; }
      byu-header:not(.byu-component-rendered) *[slot="site-title"] {
        font-size: 1.125rem;
        margin: 4px 50px 6px 20px; }
      byu-header:not(.byu-component-rendered) .subtitle[slot="site-title"] {
        margin: 7px 50px 6px 20px; }
      byu-header:not(.byu-component-rendered) byu-user-info {
        display: none; }
      byu-header:not(.byu-component-rendered) *[slot="actions"] {
        display: none; }
      byu-header:not(.byu-component-rendered) byu-search {
        display: none; }
      byu-header:not(.byu-component-rendered) byu-menu {
        display: none; } }
  @media (max-width: 50rem) {
    byu-header:not(.byu-component-rendered)::before {
      height: 16px;
      width: 56px; } }
  @media (max-width: 28rem) {
    byu-header:not(.byu-component-rendered) {
      grid-template-columns: 72px 1px auto repeat(3, min-content); }
      byu-header:not(.byu-component-rendered)::before {
        height: 15px;
        width: 54px;
        margin: auto 11px; }
      byu-header:not(.byu-component-rendered) byu-breadcrumbs {
        font-size: 13px;
        margin: 8px 40px 0 14px; }
        byu-header:not(.byu-component-rendered) byu-breadcrumbs a {
          font-size: 12px; }
      byu-header:not(.byu-component-rendered) *[slot="site-title"] {
        margin: 6px 40px 4px 14px;
        font-size: 18px; }
      byu-header:not(.byu-component-rendered) .subtitle[slot="site-title"] {
        font-size: 14px;
        margin: 6px 40px 4px 14px; } }

/*!
 /    Copyright 2019 Brigham Young University
 /
 /    Licensed under the Apache License, Version 2.0 (the "License");
 /    you may not use this file except in compliance with the License.
 /    You may obtain a copy of the License at
 /
 /        http://www.apache.org/licenses/LICENSE-2.0
 /
 /    Unless required by applicable law or agreed to in writing, software
 /    distributed under the License is distributed on an "AS IS" BASIS,
 /    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 /    See the License for the specific language governing permissions and
 /    limitations under the License.
 / */
byu-footer:not(.byu-component-rendered) {
  display: grid;
  grid-template: 1fr 82px/auto repeat(4, 300px) auto;
  background-color: #fff;
  padding-top: 60px; }
  @media (max-width: 28rem) {
    byu-footer:not(.byu-component-rendered) {
      padding-top: 30px; } }
  byu-footer:not(.byu-component-rendered)::before {
    grid-area: 2/1/3/7;
    content: "Brigham Young University";
    font-family: "Public Sans", "Noto Sans", "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.17rem;
    letter-spacing: 5px;
    font-weight: bolder;
    text-transform: uppercase;
    justify-content: center;
    color: #fff;
    position: relative;
    z-index: 100;
    height: 35px;
    margin: auto auto; }
  byu-footer:not(.byu-component-rendered)::after {
    content: "";
    background-color: #002e5d;
    grid-area: 2/1/3/7;
    color: #fff;
    position: relative;
    z-index: 50; }
  byu-footer:not(.byu-component-rendered) byu-footer-column * {
    display: block;
    font-size: 14px;
    font-family: "Public Sans", "Noto Sans", "Open Sans", Helvetica, Arial, sans-serif;
    margin: 0 0 13px 0; }
  byu-footer:not(.byu-component-rendered) byu-footer-column {
    margin: 0 40px 40px 0;
    min-width: 270px; }
    byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(1) {
      grid-area: 1/2/2/3; }
      @media (max-width: 1300px) {
        byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(1) {
          padding-left: 70px;
          margin-left: 0; } }
    byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(2) {
      grid-area: 1/3/2/4; }
    byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(3) {
      grid-area: 1/4/2/5; }
    byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(4) {
      grid-area: 1/5/2/6; }
    byu-footer:not(.byu-component-rendered) byu-footer-column p {
      color: #141414;
      line-height: 22px; }
    byu-footer:not(.byu-component-rendered) byu-footer-column a {
      color: #0062b8;
      text-decoration: none; }
    byu-footer:not(.byu-component-rendered) byu-footer-column *[slot="header"] {
      color: #141414;
      font-family: "HCo Ringside Narrow SSm", "Open Sans", Helvetica, Arial, sans-serif;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      font-weight: 700;
      font-size: 14px;
      padding: 0 0 10px;
      margin-top: 0;
      margin-bottom: 12px; }
    byu-footer:not(.byu-component-rendered) byu-footer-column byu-footer-action-button a {
      color: #fff !important;
      background-color: #0062b8;
      font-family: "HCo Ringside Narrow SSm", "Open Sans", Helvetica, Arial, sans-serif;
      font-weight: 500 !important;
      display: inline-block;
      padding: 10px 20px;
      font-size: 14px;
      box-shadow: 1px 2px 5px #a5a5a5;
      margin: 8px 0; }
    byu-footer:not(.byu-component-rendered) byu-footer-column byu-social-media-links * {
      margin: 6px 6px 6px 0; }
    byu-footer:not(.byu-component-rendered) byu-footer-column byu-social-media-links {
      display: flex;
      justify-content: flex-start; }
      byu-footer:not(.byu-component-rendered) byu-footer-column byu-social-media-links .facebook {
        content: "";
        background: url(https://cdn.byu.edu/shared-icons/latest/social/obfuscated/fa.svg) 0 0 no-repeat;
        height: 18px;
        width: 18px;
        text-indent: -99999em;
        fill: #3b5998; }
      byu-footer:not(.byu-component-rendered) byu-footer-column byu-social-media-links .instagram {
        content: "";
        background: url(https://cdn.byu.edu/shared-icons/latest/social/obfuscated/in.svg) 0 0 no-repeat;
        height: 18px;
        width: 18px;
        text-indent: -99999em;
        fill: #c32aa3; }
      byu-footer:not(.byu-component-rendered) byu-footer-column byu-social-media-links .twitter {
        content: "";
        background: url(https://cdn.byu.edu/shared-icons/latest/social/obfuscated/tw.svg) 0 0 no-repeat;
        height: 18px;
        width: 18px;
        text-indent: -99999em;
        fill: #1da1f2; }
      byu-footer:not(.byu-component-rendered) byu-footer-column byu-social-media-links .linkedin {
        content: "";
        background: url(https://cdn.byu.edu/shared-icons/latest/social/obfuscated/li.svg) 0 0 no-repeat;
        height: 18px;
        width: 18px;
        text-indent: -99999em;
        fill: #007bb5; }
      byu-footer:not(.byu-component-rendered) byu-footer-column byu-social-media-links .youtube {
        content: "";
        background: url(https://cdn.byu.edu/shared-icons/latest/social/obfuscated/yo.svg) 0 0 no-repeat;
        height: 18px;
        width: 18px;
        text-indent: -99999em;
        fill: red; }
  @media (max-width: 60rem) {
    byu-footer:not(.byu-component-rendered) {
      grid-template: repeat(2, min-content) 56px/auto repeat(2, 50%) auto;
      padding: 6px 0 0; }
      byu-footer:not(.byu-component-rendered)::before {
        grid-area: 3/1/4/5;
        width: 300px;
        height: 26px; }
      byu-footer:not(.byu-component-rendered)::after {
        grid-area: 3/1/4/5; }
      byu-footer:not(.byu-component-rendered) byu-footer-column {
        margin: 12px 8px 0; }
        byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(1) {
          grid-area: 1/2/2/3;
          padding-left: 0;
          margin-left: 12px; }
        byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(2) {
          grid-area: 1/3/2/4; }
        byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(3) {
          grid-area: 2/2/3/3;
          margin-bottom: 50px; }
        byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(4) {
          grid-area: 2/3/3/4;
          margin-bottom: 50px; }
        byu-footer:not(.byu-component-rendered) byu-footer-column *[slot="header"] {
          margin: 0; }
        byu-footer:not(.byu-component-rendered) byu-footer-column byu-footer-action-button a {
          font-size: 11px;
          padding: 7px 23px; } }
  @media (max-width: 50rem) {
    byu-footer:not(.byu-component-rendered) {
      grid-template: repeat(4, min-content) 76px/auto 100% auto; }
      byu-footer:not(.byu-component-rendered)::before {
        grid-area: 5/1/6/4; }
      byu-footer:not(.byu-component-rendered)::after {
        grid-area: 5/1/6/4; }
      byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(1) {
        grid-area: 1/2/2/3; }
      byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(2) {
        grid-area: 2/2/3/3; }
      byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(3) {
        grid-area: 3/2/4/3;
        margin-bottom: 0; }
      byu-footer:not(.byu-component-rendered) byu-footer-column:nth-child(4) {
        grid-area: 4/2/5/3; } }

/*!
 * /    Copyright 2019 Brigham Young University
 * /
 * /    Licensed under the Apache License, Version 2.0 (the "License");
 * /    you may not use this file except in compliance with the License.
 * /    You may obtain a copy of the License at
 * /
 * /        http://www.apache.org/licenses/LICENSE-2.0
 * /
 * /    Unless required by applicable law or agreed to in writing, software
 * /    distributed under the License is distributed on an "AS IS" BASIS,
 * /    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * /    See the License for the specific language governing permissions and
 * /    limitations under the License.
 * / */
byu-header [slot=site-title] a, byu-header a[slot=site-title] {
  color: #fff;
  text-decoration: none; }

byu-header {
  z-index: 1; }
  byu-header a[slot="site-title"], byu-header [slot="site-title"] a {
    color: #fff !important;
    display: inline-block;
    font-size: inherit;
    font-weight: 400;
    bottom: 1px;
    text-decoration: none;
    clear: right;
    order: 1;
    margin: 0.25em 0 !important; }
  byu-header [slot="actions"] {
    color: #fff; }
    byu-header [slot="actions"] a {
      color: #fff;
      text-decoration: none;
      display: block;
      padding: 100px 24px;
      margin: 0 -24px; }
    byu-header [slot="actions"]:hover {
      color: #002e5d; }
      byu-header [slot="actions"]:hover a {
        color: #002e5d; }

/*!
 * /    Copyright 2019 Brigham Young University
 * /
 * /    Licensed under the Apache License, Version 2.0 (the "License");
 * /    you may not use this file except in compliance with the License.
 * /    You may obtain a copy of the License at
 * /
 * /        http://www.apache.org/licenses/LICENSE-2.0
 * /
 * /    Unless required by applicable law or agreed to in writing, software
 * /    distributed under the License is distributed on an "AS IS" BASIS,
 * /    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * /    See the License for the specific language governing permissions and
 * /    limitations under the License.
 * / */
byu-search input.__byu-search-selected-input {
  display: block;
  width: 100%;
  padding: 12px 20px;
  line-height: 25.6px;
  border: 1px solid #fff;
  border-bottom: 1px solid #ddd;
  font-family: "HCo Ringside Narrow SSm", "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  border-radius: 0;
  margin-bottom: 0;
  -webkit-appearance: none;
  -moz-appearance: none; }
  @media (min-width: 50rem) {
    byu-search input.__byu-search-selected-input {
      width: 144px;
      height: 35px;
      padding: 0.75em 0.65em;
      border: 1px solid #fff; } }
  byu-search input.__byu-search-selected-input::placeholder {
    font-family: "HCo Ringside Narrow SSm", "Open Sans", Helvetica, Arial, sans-serif;
    opacity: 1;
    color: #8f8f8f !important; }
  @supports (display: grid) {
    @media (min-width: 50rem) {
      byu-search input.__byu-search-selected-input {
        padding: 0.4em 1em;
        border-radius: 0.9625em; } } }

html {
  font-size: 16px !important; }

body {
  margin: 0;
  padding: 0;
  background-color: #FAFAFA; }
